/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import _ from 'lodash';
import autosize from 'autosize';

/* Imports ---------------------------------------------------------*/

import './breakpoints';
import './slider';
import './equalizer';
import './modals';
import './scrollAnimations';
import './selects';
import './filters';

// ==================================================================
// Helpers

function random(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function lastInRow($element) {
	var $siblings = $element.parent().find('>*');
	let offsetTop = $element.offset().top;
	var $item = $element;

	$siblings.slice($item.index()).each(function () {
        if ($(this).offset().top > offsetTop) {
            return false;
        } else {
            $item = $(this);
        }
    });

    return $item;
}

// ==================================================================
// Variables

let $body = $('body');
let currentURL = location.protocol+'//'+location.host+location.pathname;

// ==================================================================
// Autosize

autosize($('textarea'));

// ==================================================================
// Accordions

$('.js-accordion').each(function(){
	let $block = $(this);
	let $trigger = $block.find('.js-accordion-trigger');

	$trigger.each(function(){
		let $thisTrigger = $(this);
		let $content = $thisTrigger.next('.js-accordion-content');
		let canOpen = 1;
		
		$thisTrigger.on('click', function(e){
			e.preventDefault();
			if (canOpen) {
				canOpen = 0;
				if ($(this).hasClass('open')) {
					$content.slideUp();
				}else {
					$content.slideDown();
				}

				$thisTrigger.toggleClass('open');
				
				setTimeout(function(){
					canOpen = 1;
				}, 500);
			}
		})
	})
});

// ==================================================================
// Accordion Grid

$('.js-accordion-grid').each(function(){
	var $grid = $(this);

	$('body').on('click', '.js-accordion-grid-item', function(e){
		e.preventDefault();

		var $this = $(this);
		var $target = lastInRow($this);
		var ajaxURL = $this.data('api');

		if (!$this.hasClass('js-active')) {
			var $template;

			$.ajax({
			  	dataType: "html",
			  	url: currentURL + ajaxURL,
			  	success: function(response){
			  		var $template = $(response);
					
					$grid.find('.js-active').removeClass('js-active');
					$this.addClass('js-active');

					$grid.find('.js-accordion-grid-content').slideUp(function(){
						$(this).remove();
					});

					$target.after($template);

					$grid.find('.js-accordion-grid-content').slideDown();
			  	}
			});

		} else {
			$grid.find('.js-accordion-grid-content').slideUp(function(){
				$this.blur();
				$grid.find('.js-active').removeClass('js-active');
				$(this).remove();
			});
		}
	});

	$('body').on('click', '.js-accordion-grid-content .svg-close', function(){
		$grid.find('.js-accordion-grid-content').slideUp(function(){
			$grid.find('.js-active').removeClass('js-active');
			$(this).remove();
		});
	});

	$(window).on('resize', function(){
		$grid.find('.js-accordion-grid-content').slideUp(function(){
			// $this.blur();
			$grid.find('.js-active').removeClass('js-active');
			$(this).remove();
		});
	})
});

// ==================================================================
// Search Validation -- Needs to have a value before submit

$('.searchModal form, .searchResults form').on('submit', function(e){
	let $this = $(this);
	let $input = $this.find('input[type="text"]');

	if ($input.val() == '') {
		e.preventDefault();
	}
});

// ==================================================================
// Background Videos

$('.js-background-video').each(function(){
	let $this = $(this);
	let settings = {};
	let videoEmbed = new Embed($this.data('src'), {
		autoplay: $this.data('autoplay'),
		loop: $this.data('loop'),
		muted: 1,
		mute: 1,
	});
	let videoTemplate = `<div class="js-video-background-embed">${videoEmbed.render()}</div>`;
	let $video = $(videoTemplate);

    let setSize = function() {
        settings.height = $video.find('iframe').height();
        
        if (settings.height < $this.height()) {
            $video.css({
                transform: `translate(-50%,-50%) scale(${$this.height() / settings.height})`,
            })
        }
	}

	if ($this.data('aspect') != undefined) {
		settings.ratio = $this.data('aspect').split(',');

		$video.css({
		    'padding-bottom': settings.ratio[1] / settings.ratio[0] * 100 + "%",
		});

		$this.append($video);

		$(window).on('resize', _.throttle(function(){
			setSize();
		}, 50));

		setSize();
    }else {
    	console.warn('data-aspect="x,y" is required to scale video.');
    	return;
    }

});


//