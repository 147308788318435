/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
const slick = require('slick-carousel');

// ==========================================//
// Slick Slider with SCSS Float Grid
// ==========================================//

const getBreakpoints =  function($el) {
	// Apply breakpoint classes to .js-slider
	// Works using class names `bp-up-i` so `sm-up-4` outputs 4 slides to be shown on small screens upwards
	let breakpoints = {init: 0,xxs: 480,xs: 540,sm: 640,md: 768,xmd: 968,lg: 1024,xl: 1280,xxl: 1440};
	let responsiveBreakpoints = [];
	let classBreakpoints = $el[0].className.split(' ');

	classBreakpoints.forEach(function (value) {
		if(value.indexOf(`-up-`) > -1){
			let breakpoint = value.split('-')[0];
			let slides = parseInt(value.split('-').pop().trim());
			responsiveBreakpoints.push({breakpoint: breakpoints[breakpoint],settings: {slidesToShow: slides,slidesToScroll: slides}});
		}
	});

	return responsiveBreakpoints;
}

$('.js-slider').each(function(){
	let $slider = $(this);

	// getBreakpoints($slider)

	$slider.slick({
		// Options
		// dots: true,
		arrows: true,
		// speed: 500,
		// fade: true,
		mobileFirst: true,
		infinite: true, 
		// cssEase: 'linear',
		prevArrow:`<button class="slick-left"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="17px" viewBox="0 0 10 17" version="1.1" class="svg-chevron-right"><g transform="translate(-25.000000, -5.000000)" fill="#979797"><path d="M32,10 L32,19 L30,19 L30,10 L21,10 L21,8 L32,8 L32,10 Z" id="Combined-Shape" transform="translate(26.500000, 13.500000) rotate(-315.000000) translate(-26.500000, -13.500000) "></path></g></svg></button>`,
		nextArrow:`<button class="slick-right"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="17px" viewBox="0 0 10 17" version="1.1" class="svg-chevron-right"><g transform="translate(-25.000000, -5.000000)" fill="#979797"><path d="M32,10 L32,19 L30,19 L30,10 L21,10 L21,8 L32,8 L32,10 Z" id="Combined-Shape" transform="translate(26.500000, 13.500000) rotate(-315.000000) translate(-26.500000, -13.500000) "></path></g></svg></button>`,
		responsive: getBreakpoints($slider)
	});
});


const $sliderBlock = $('.js-slider-block');

$('.js-slider-block').each(function(){
	let $slider = $(this);
	let $slides = $slider.find('.js-slide');
	let $currentSlide;
	let $nextSlides;
	let $prevSlides;
	let initSlide;

	($slides.length >= 3) ? initSlide = 2 : initSlide = $slides.length - 1;

	$slider.slick({
		// Options
		dots: false,
		arrows: false,
		// speed: 500,
		// fade: true,
		draggable: false,
		swipe: false,
		mobileFirst: true,
		initialSlide: 10,
		// variableWidth: true,
		focusOnSelect: true,
		centerMode: true,
		infinite: false, 
		// cssEase: 'linear',
		// prevArrow:`<button class="slick-left"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="17px" viewBox="0 0 10 17" version="1.1" class="svg-chevron-right"><g transform="translate(-25.000000, -5.000000)" fill="#979797"><path d="M32,10 L32,19 L30,19 L30,10 L21,10 L21,8 L32,8 L32,10 Z" id="Combined-Shape" transform="translate(26.500000, 13.500000) rotate(-315.000000) translate(-26.500000, -13.500000) "></path></g></svg></button>`,
		// nextArrow:`<button class="slick-right"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="17px" viewBox="0 0 10 17" version="1.1" class="svg-chevron-right"><g transform="translate(-25.000000, -5.000000)" fill="#979797"><path d="M32,10 L32,19 L30,19 L30,10 L21,10 L21,8 L32,8 L32,10 Z" id="Combined-Shape" transform="translate(26.500000, 13.500000) rotate(-315.000000) translate(-26.500000, -13.500000) "></path></g></svg></button>`,
		responsive: getBreakpoints($slider)
	});

	$slider.on('click', '.slick-slide', function(){
		arrangeSlides(this);
	})

	const arrangeSlides = function(selector = '.slick-current') {
		$currentSlide = $(selector);
	    $nextSlides = $currentSlide.nextAll();
	    $prevSlides = $currentSlide.prevAll();

	    if ($currentSlide.hasClass('prev-slide') || $currentSlide.hasClass('next-slide')) {
			$currentSlide.removeClass('next-slide prev-slide');
	    }

	    $slider.find('.next-slide, .prev-slide').removeClass('next-slide prev-slide');

	    $nextSlides.addClass('next-slide');
	    $prevSlides.addClass('prev-slide');
		$currentSlide.addClass('slick-current');
	}

	arrangeSlides();
});

