// ==========================================//
// Filters
// ==========================================//

import $ from 'jquery';

$('.js-filters').each(function(){
	const $group  = $(this);
	const $filter = $group.find('.js-filters-input');
	const $target = $group.find('.js-filters-target');
	const $load   = $group.find('.js-filters-load');
	const apiURL  = $group.data('api-url');
	let params = '';

	const getPrefix = function(i) {
		return (i == 0) ? '?' : '&';
	}

	const getParams = function(){
		params = '';
		let i = 0;
		
		$filter.each(function(){
			let $thisFilter = $(this);

			if ($thisFilter.is('select')) {
				if ($thisFilter.find(':selected').val() != 0) {
					params += `${getPrefix(i) + $thisFilter.data('parameter')}=${$thisFilter.find(':selected').val()}`;
					i++;
				}
			}else {
				if ($thisFilter.hasClass('js-selected')) {
					if ($thisFilter.data('value') != 0) {
						params += `${getPrefix(i) + $thisFilter.data('parameter')}=${$thisFilter.data('value')}`;
						i++;
					}
				}
			}
		});
		console.log(params);
	}

	const getItems = function(render, loadmore) {
		$.ajax({
			url: apiURL + params,
			dataType: "JSON",
			success: function(result){
				let itemsArray = result.data.items;
				let canLoadMore = result.data.load_more;

				// If we want the response rendered in the template.
				if (render) {
					// If we aren't loading more items, clear the html
					if (!loadmore) {
						$target.html('');
					}
					// Loop through new items and render them onto the template
					for (var i = 0; i < itemsArray.length; i++) {
						$target.append($(itemsArray[i]));
					}
				}

				// Test state of load more button.
				(canLoadMore) ? $load.removeClass('disabled') : $load.addClass('disabled');
			}
		})
	}

	// All events we want fired to 
	const filterItems = function(render = false, loadmore = false) {
		getParams();
		getItems(render, loadmore);
	}

	$filter.each(function(){
		let $thisFilter = $(this);

		if ($thisFilter.is(':input')) {
			$thisFilter.on('change', function(){
				// arguments true, false
				filterItems();
			});
		}else {
			$thisFilter.on('click', function(e){
				e.preventDefault();
				$filter.removeClass('js-selected');
				$thisFilter.addClass('js-selected');
				// arguments true, false
				filterItems();
			});
		}	
	});

	$load.on('click', function(){
		// If this button isn't disabled - if items can be loaded.
		if (!$(this).hasClass('disabled')) {
			filterItems(true, true);
		}
	})

	// Initialise the load more button
	filterItems();		
	// This function will check currently selected parameters and will
	// enable / disabled the load more button depending on the response.
	// No content will change on the page.
})