import $ from 'jquery';
import throttle from 'lodash/throttle';

function getBreakpointWidth(selector, classToToggle, extraSpace = 0) {
	$(selector).each(function(){
		let $el = $(this);
		let $item = $el.find(`${selector}-watch`);
		let width = extraSpace;

		for (var i = 0; i < $item.length; i++) {
			width += $item.eq(i).width();
			width += parseInt($item.eq(i).css('margin-right'));
			width += parseInt($item.eq(i).css('margin-left'));
			width += parseInt($item.eq(i).css('padding-right'));
			width += parseInt($item.eq(i).css('padding-left'));
		};

		$(window).on('resize', throttle(function(){
			breakpointToggle($el, width, classToToggle);
		}, 150));

		breakpointToggle($el, width, classToToggle);
	})
}

function breakpointToggle($el, breakpoint, classToToggle) {
	if ($(window).width() > breakpoint) {
		if ($el.hasClass(classToToggle)) {
			$el.removeClass(classToToggle);
		}
	}else {
		if (!$el.hasClass(classToToggle)) {
			$el.addClass(classToToggle);
		}
	}
}

getBreakpointWidth('.js-nav', 'hamburger', 250);
getBreakpointWidth('.js-footer', 'mobile', 250);