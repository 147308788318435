import $ from 'jquery';
import _ from 'lodash';

// ==========================================//
// Equalize
// ==========================================//

(function ( $ ) {

	$.fn.inView = function() {
		if ($(window).scrollTop() + $(window).height() >= $(this).offset().top && $(window).scrollTop() <= $(this).offset().top + $(this).height()) {
			return true;
		}
		return false;
	};

	function matchHeight($target) {
	    // Initialise at 0
	    var currentHeight = 0;

	    // All targets to show real height
	    $target.css('height', 'auto').addClass('clearfix');

	    // Test each target height against initial height
	    for (var i = 0; i < $target.length; i++) {
	    	if ($target.eq(i).height() >= currentHeight) {
	    		currentHeight = $target.eq(i).height();
	    	}
	    }

	    // Set all targets to have equal height
	    $target.height(currentHeight);
	    // console.log($target, currentHeight);
	}

	$.fn.equalize = function( options ) {

		var $this = $(this);
		var $target;

	    // Control over how often this even is fired
	    var settings = $.extend({
	    	buffer: 500
	    }, options );

	    // when == 1 event will fire on scroll;
	    var widthChanged = 1;

	    // Find target
	    if ($this.data('equalize') != undefined) {
	    	$target = $this.find(`[data-equalize-watch="${$this.data('equalize')}"]`);
	    }else {
	    	$target = $this.find(`[data-equalize-watch]`);
	    }

	    matchHeight($target);

	    // On scroll event
	    $(window).on('scroll', _.throttle(function(){

	      // If this container is visible and the content has changed width
	      if (widthChanged && $this.inView()) {

	        // Tell function width has no longer changed
	        widthChanged = 0;

	        // Match target heights
	        matchHeight($target);
	    }
	}, settings.buffer));

	    // On resize event
	    $(window).on('resize', _.debounce(function(){

	       // Tell function width has changed
	       widthChanged = 1;

	      // If this container is visible
	      if ($this.inView()) {

	        // Match target heights
	        matchHeight($target);
	    }

	}, settings.buffer))

	};

}( $ ));

$('[data-equalize]').each(function(){
	$(this).equalize();
});
